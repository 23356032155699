import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { QuizResultProvider } from "./src/context/QuizResult";
import { theme } from "./src/ui/theme";
import { Seo } from "./src/components/Seo";

import "@fontsource/barlow/400.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/700.css";
import "./src/styles/global.scss";

// https://github.com/gatsbyjs/gatsby/discussions/17914
export const replaceHydrateFunction = () => {
	return (element, container, callback) => {
		ReactDOM.render(element, container, callback);
	};
};

export const wrapRootElement = ({ element }) => (
	// Context API provider
	<>
		<Seo />
		<QuizResultProvider>{element}</QuizResultProvider>
	</>
);
export const wrapPageElement = ({ element }) => (
	<ThemeProvider theme={theme}>{element}</ThemeProvider>
);
