import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const Colors = {
	Green: "#539b7b",
	LightGreen: "rgb(133, 179, 153)",
	TransparentGreen: "rgba(78,150,118,0.1)",
	Text: "#3D405B",
	LightText: "#616479",
	Grey: "#d8d9de",
	LightGrey: "#9e9fad",
	RedOrange: "#ff3000",
	White: "#FFFFFF",
	LightHover: "rgba(0, 0, 0, 0.04)",
	Background: "rgb(251,251,252)",
	PersonalityTypeHighlightBackground: "#f7f8f9",
	DownloadMobilesBackground: "rgb(245,245,247)",
} as const;

export const QuestionOptionsColors = {
	G1: Colors.Green,
	G2: Colors.LightGreen,
	G3: "rgb(193, 214, 203)",
	G4: "rgb(239, 245, 242)",
	R1: "rgb(221, 116, 113)",
	R2: "rgb(217, 148, 144)",
	R3: "rgb(231, 188, 185)",
	R4: "rgb(241, 215, 213)",
	GR1: "rgb(199,199,199)",
	GR2: "rgb(210, 210, 210)",
	GR3: "rgb(227, 227, 227)",
};

export type TColors = typeof Colors[keyof typeof Colors];

const breakpoints = createBreakpoints({});

const theme = createTheme({
	palette: {
		primary: {
			main: Colors.Green,
			light: Colors.LightGreen,
		},
		secondary: {
			main: Colors.RedOrange,
		},
		action: {
			hover: Colors.Green,
		},
		text: {
			primary: Colors.Text,
		},
	},
	typography: {
		fontFamily: "Barlow, Arial, Helvetica, sans-serif",
		button: {
			fontWeight: "bold",
		},
		h1: {
			fontWeight: 500,
			letterSpacing: "-0.53px",
			color: Colors.Text,
			[breakpoints.down("md")]: {
				fontSize: "2rem",
			},
			[breakpoints.up("md")]: {
				fontSize: "2.875rem",
			},
		},
		h2: {
			fontWeight: 500,
			letterSpacing: "-0.1px",
			display: "block",
			color: Colors.Text,
			[breakpoints.down("md")]: {
				fontSize: "1.5rem",
			},
			[breakpoints.up("md")]: {
				fontSize: "2rem",
			},
		},
		h3: {
			fontWeight: 500,
			letterSpacing: "-0.2px",
			color: Colors.Text,
			[breakpoints.down("md")]: {
				fontSize: "1.25rem",
			},
			[breakpoints.up("md")]: {
				fontSize: "1.75rem",
			},
		},
		h4: {
			fontWeight: 500,
			lineHeight: "1.5rem",
			letterSpacing: "-0.2px",
			color: Colors.Text,
			[breakpoints.down("md")]: {
				fontSize: "1.125rem",
			},
			[breakpoints.up("md")]: {
				fontSize: "1.25rem",
			},
		},
		body1: {
			fontWeight: 500,
			lineHeight: "1.375rem",
			letterSpacing: "-0.2px",
			color: Colors.Text,
			[breakpoints.down("md")]: {
				fontSize: "1rem",
			},
			[breakpoints.up("md")]: {
				fontSize: "1.125rem",
			},
		},
		body2: {
			fontWeight: 400,

			lineHeight: "1.1875rem",
			letterSpacing: "-0.2px",
			color: Colors.Text,
			[breakpoints.down("md")]: {
				fontSize: "0.875rem",
			},
			[breakpoints.up("md")]: {
				fontSize: "1rem",
			},
		},
		caption: {
			fontWeight: 500,
			opacity: "0.5",
			fontSize: "0.75rem",
			lineHeight: "1.25rem",
			letterSpacing: "-0.15px",
			color: Colors.Text,
		},
	},
});

export { theme, Colors };
