// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-result-tsx": () => import("./../../../src/pages/result.tsx" /* webpackChunkName: "component---src-pages-result-tsx" */),
  "component---src-pages-share-fit-tsx": () => import("./../../../src/pages/share-fit.tsx" /* webpackChunkName: "component---src-pages-share-fit-tsx" */),
  "component---src-pages-share-quiz-tsx": () => import("./../../../src/pages/share-quiz.tsx" /* webpackChunkName: "component---src-pages-share-quiz-tsx" */),
  "component---src-pages-types-tsx": () => import("./../../../src/pages/types.tsx" /* webpackChunkName: "component---src-pages-types-tsx" */),
  "component---src-ui-templates-quiz-page-template-index-tsx": () => import("./../../../src/ui/templates/QuizPageTemplate/index.tsx" /* webpackChunkName: "component---src-ui-templates-quiz-page-template-index-tsx" */)
}

