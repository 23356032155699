import * as React from "react";
import { Helmet } from "react-helmet";

const META_TITLE = "hiree - darmowy test preferencji zawodowych";
const META_DESCRIPTION = "Sprawdź jakie stanowiska pasują do Ciebie";

const Seo = () => {
	return (
		<Helmet
			htmlAttributes={{
				lang: "pl-PL",
			}}
			title={META_TITLE}
			meta={[
				{
					name: `description`,
					content: META_DESCRIPTION,
				},
				{
					property: `og:title`,
					content: META_TITLE,
				},
				{
					property: `og:description`,
					content: META_DESCRIPTION,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: `/og.png`
				}
			]}
		/>
	);
};

export { Seo };
