import React from "react";
import { initialState, QuizResultState } from "./initialState";

type Action =
	| { type: "SAVE"; payload: { id: number; value: number } }
	| { type: "CLEAR" }
	| { type: "SET_ACTIVE"; payload: { id: number } };
type Dispatch = (action: Action) => void;

const QuizResultContext = React.createContext<
	| { state: QuizResultState; dispatch: Dispatch }
	| { state: QuizResultState; dispatch: undefined }
>({ state: initialState, dispatch: undefined });

function quizResultReducer(state: QuizResultState, action: Action) {
	switch (action.type) {
		case "SAVE": {
			const isPreviousAnswer = action.payload.id < state.activeQuestion;
			return {
				...state,
				[action.payload.id]: action.payload.value,
				activeQuestion: isPreviousAnswer ? state.activeQuestion : action.payload.id + 1,
			};
		}
		case "CLEAR": {
			return { ...initialState };
		}
		case "SET_ACTIVE": {
			return { ...state, activeQuestion: action.payload.id };
		}
		default: {
			const exhaustiveCheck: never = action;
			throw new Error(`Unhandled action type: ${exhaustiveCheck}`);
		}
	}
}

function QuizResultProvider({ children }) {
	const [state, dispatch] = React.useReducer(quizResultReducer, initialState);
	const value = { state, dispatch };
	return (
		<QuizResultContext.Provider value={value}>{children}</QuizResultContext.Provider>
	);
}

function useQuizResult() {
	const context = React.useContext(QuizResultContext);
	const isServerSide = typeof window === "undefined";

	if (context === undefined && !isServerSide) {
		throw new Error("useQuizResult must be used within a QuizResultProvider");
	}
	return context;
}

export { QuizResultProvider, useQuizResult };
